// Images
import aboutUs1 from '../../../../images/iconos-pagina/quienes somos/group.png'
import aboutUs from '../../../../images/iconos-pagina/quienes somos/siting-people.png'
// Styles
import styles from './aboutUs.module.css'

const AboutUs = () => {

  return (
    <section className={styles.quienes_somos} id='about_us'>
      <div className={styles.container}>
        <div className={styles.imagen}>
          <img className={styles.img_quienes_somos} src={aboutUs} alt="quienes_somos" />
        </div>
        <div className={styles.aboutUsDefinition}>
          <h2>¿Quiénes Somos?</h2>
          <p className={styles.definicion}>
            Somos tu solución en momentos financieros importantes.
            Ofrecemos créditos instantáneos sin complicaciones.
            Abrimos la puerta al sistema financiero para ti.
            Completa el proceso en 10 minutos y recibirás el depósito
            en un plazo máximo de 24 horas.
          </p>
        </div>
        <div className={styles.imagen}>
          <img className={styles.img_quienes_somos} src={aboutUs1} alt="quienes_somos" />
        </div>
      </div>
    </section>
  )
}

export default AboutUs