// Images
import banner from '../../../../images/happy-young-colleagues-sitting-office-coworking-using-laptop.jpg'
// Styles
import styles from './banner.module.css'

const Banner = () => {

  return (
    <section className={styles.banner_section}>
      <img className={styles.foto} src={banner} alt="banner" />
      <div className={styles.contenedor_texto}>
        <h3>
          Pequeñas soluciones, grandes posibilidades. <br />
          Encuentra el impulso financiero que necesitas <br />
          para alcanzar tus objetivos
        </h3>
        <a target='_blank' href="https://preztum.com/">
          <button className={styles.boton_credito2}>Solicitar mi crédito ya!!</button>
        </a>
      </div>
    </section>
  )
}

export default Banner