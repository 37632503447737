// Styles
import styles from './footer.module.css'
// Images
import imageEmail from '../../images/iconos-pagina/Footer/mail.png'
import imageWhatsapp from '../../images/iconos-pagina/Footer/whatsapp.png'
// Libraries
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {

  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className={styles.footer}>

      <div className={styles.main}>

        <div className={styles.containerText}>
          <div className={styles.contact}>
            <div>
              <h3 className={styles.title}>Contáctanos</h3>
              <a className={styles.mainNetwork} target="__blank" href='mailto:administración@preztum.com'>
                <figure className={styles.icons}>
                  <img src={imageEmail} alt="Correo electrónico" />
                </figure>
                <p>administración@preztum.com</p>
              </a>
              <a className={styles.mainNetwork} target="__blank" href='https://wa.me/3113392881?text=¡Estoy+interesado!'>
                <figure className={styles.icons}>
                  <img src={imageWhatsapp} alt="Whatsapp" />
                </figure>
                <p>+56 3113392881</p>
              </a>
            </div>

            <div>
              <h3 className={styles.title} style={{ marginBottom: '3rem' }} >Síguenos en nuestras
                <br />redes sociales
              </h3>
              <div className={styles.networks}>
                <a target="__blank" href='https://www.facebook.com/PREZTUM/'>
                  <FaFacebook className={styles.reactIcons} />
                </a>
                <a target="__blank" href='https://wa.me/3113392881?text=¡Estoy+interesado!'>
                  <FaWhatsapp className={styles.reactIcons} />
                </a>
                <a target="__blank" href='https://www.instagram.com/preztum/'>
                  <FaInstagram className={styles.reactIcons} />
                </a>
              </div>
            </div>
          </div>
          <p class={styles.copyright}>Copyright & copy {year} Preztum - Todos los derechos reservados. </p>
        </div>

      </div>

    </footer>
  )
}

export default Footer