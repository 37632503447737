// Components
import Landing from './views/landing/landing';
import Header from './components/header/header';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';

function App() {

  return (
    <>
      <Header />
      <Landing />
      <Footer />
    </>
  );
}

export default App;
