import { useState } from 'react';
// Images
import logoPreztum from '../../images/logo Preztum.png'
// Styles
import styles from './header.module.css'
// Libraries
import { MdClose, MdMenu } from 'react-icons/md';
// Components
import Navbar from '../navbar/navbar';

const Header = () => {
  const [isNavbar, setIsNavbar] = useState(false)

  return (
    <header>
      <figure className={styles.logo}>
        <img className={styles.logo_preztum} src={logoPreztum} alt="logo-Preztum" />
      </figure>
      <nav className={styles.nav}>
        <ul>
          <li><a href='#main'>Inicio</a></li>
          <li><a href='#about_us'>¿Quiénes Somos?</a></li>
          <li><a href='#faqs'>Preguntas Frecuentes</a></li>
        </ul>
      </nav>
      <div className={styles.containerButton} >
        <a href="https://preztum.com/" target='__blank' >
          <button className={styles.boton_credito}>Solicitar Crédito</button>
        </a>
      </div>
      {!isNavbar
        ? <MdMenu onClick={() => setIsNavbar(!isNavbar)} className={styles.menuHamburguer} />
        : <MdClose onClick={() => setIsNavbar(!isNavbar)} className={styles.menuHamburguer} />
      }
      {isNavbar && <Navbar setIsNavbar={setIsNavbar} />}
    </header>
  )
}

export default Header