// Styles
import styles from './missionAndVission.module.css'
// Images
import mision from '../../../../images/iconos-pagina/mision y vision/mision.png'
import vision from '../../../../images/iconos-pagina/mision y vision/vision.png'
import fusion from '../../../../images/iconos-pagina/mision y vision/fusion.png'

const MissionAndVission = () => {

  return (
    <section>
      <figure className={styles.figure}>
        <img src={fusion} alt="Misión y Visión" />
      </figure>
      <div className={styles.container}>
        <div className={styles.mission}>
          <h2>Misión</h2>
          <p> Somos una empresa dinámica, tecnológica e innovadora
            que busca contribuir al país generando acceso financiero
            todos los sectores sociales.</p>
          <img src={mision} alt="Misión" />
        </div>
        <div className={styles.vision}>
          <h2>Visión</h2>
          <p> En el año 2026 estaremos entre las fintech mas importantes del país,
            consolidando un equipo de trabajo optimo, logrando estar a la vanguardia
            de la tecnología generando constantes Innovaciones</p>
          <img src={vision} alt="Visión" />
        </div>
      </div>
    </section>
  )
}

export default MissionAndVission