// Styles
import styles from './FAQ.module.css'
// Image
import imageTitle from '../../../../images/iconos-pagina/preguntas frecuentes/question.png'
import imageQuestionsGroup from '../../../../images/iconos-pagina/preguntas frecuentes/question-group.png'

const FAQ = () => {

  return (
    <section id='faqs' className={styles.main}>
      <div className={styles.title}>
        <p className={styles.preguntas_frecuentes}>Preguntas Frecuentes</p>
        <figure className={styles.figureImage}>
          <img src={imageTitle} alt='Preguntas frecuentes' />
        </figure>
      </div>
      <div className={styles.container_preguntas_frecuentes}>
        <dl className={styles.textQuestion}>

          <dt>1. ¿Qué es PREZTUM?:</dt>
          <dd>Somos una plataforma que permite solucionar necesidades financieras eventuales,
            hacemos créditos al instante de una manera fácil, practica y sin codeudores.
            u</dd>

          <dt>2. ¿Qué necesito para solicitar un credito?:</dt>
          <dd>Ser Colombiano, y tener tu cédula a la mano.</dd>

          <dt>3. ¿En cuanto tiempo puedo disponer de mi dinero?:</dt>
          <dd>En menos de 24 horas.</dd>

          <dt>4. ¿Dónde y cómo puedo pagar?:</dt>
          <dd>
            Tenemos todas estas facilidades:
            <br /> Transferencia bancaria PSE: efecty, gana, puntored, WesterUnion.
            <br /> Efectivo: Baloto, Susuerte, Su red, Apostar, refácil, súper giros.
            <br /> Tarjeta de créditos: Master card, Visa, American Express, Diners Club, Codensa.
          </dd>

          <dt>5. ¿Qué pasa si me atraso o no puedo pagar?:</dt>
          <dd>
            Te daremos todas las posibilidades para que no te atrases,
            pero si lastimosamente esto sucede, serás reportado a la centrales de riesgo.
          </dd>

          <dt>6. ¿Debo tener historial de crédito?:</dt>
          <dd>
            No, Preztum tiene en cuenta diferentes variables para brindarte mayor
            oportubidad de acceso al crédito.
          </dd>

          <dt>7. ¿Puedo pagar antes mi crédito?:</dt>
          <dd>Por supuesto, sin ningún tipo de penalización.</dd>
        </dl>
        <figure className={styles.imageQuestions}>
          <img src={imageQuestionsGroup} alt='Preguntas frecuentes' />
        </figure>
      </div>
    </section>
  )
}

export default FAQ