// Styles
import styles from './navbar.module.css'

const Navbar = (props) => {

  const { setIsNavbar } = props

  return (
    <div className={styles.navbar}>
      <nav className={styles.nav}>
        <ul className={styles.containerItems}>
          <li onClick={() => setIsNavbar(false)}><a href='#main'>Inicio</a></li>
          <li onClick={() => setIsNavbar(false)}><a href='#about_us'>¿Quiénes Somos?</a></li>
          <li onClick={() => setIsNavbar(false)}><a href='#faqs'>Preguntas Frecuentes</a></li>
        </ul>
      </nav>
      <div>
        <a href="https://preztum.com/" target='__blank' >
          <button className={styles.boton_credito}>Solicitar Crédito</button>
        </a>
      </div>
    </div>
  )
}

export default Navbar