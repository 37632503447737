// Styles
import styles from './sectionItemsInfo.module.css'

const SectionItemsInfo = (props) => {

  const { items, title } = props

  return (
    <section>
      <div id='section'>
        <p className={styles.solicitar}>{title}</p>
      </div>
      <div className={styles.services_container}>
        {items.map(requirement => {
          return (
            <div className={styles.service}>
              <h4>{requirement.text}</h4>
              <img className={styles.image} src={requirement.image} alt={requirement.text} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default SectionItemsInfo