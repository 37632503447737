// Components
import Banner from './components/banner/banner'
import SectionItemsInfo from './components/sectionItemsInfo/sectionItemsInfo'
// Images
import casa from '../../images/iconos-pagina/4 pasos/house.png'
import celular from '../../images/iconos-pagina/4 pasos/cellphone.png'
import mayores18 from '../../images/iconos-pagina/4 pasos/18years.png'
import identificacion from '../../images/iconos-pagina/4 pasos/id-card.png'

import dineroDirecto from '../../images/iconos-pagina/como hacerlo/money.png'
import simulasCredito from '../../images/iconos-pagina/como hacerlo/credit.png'
import firmar from '../../images/iconos-pagina/como hacerlo/business-contract.png'
import diligenciarSolicitud from '../../images/iconos-pagina/como hacerlo/request.png'
// Components
import FAQ from './components/FAQ/FAQ'
import Allies from './components/allies/allies'
import AboutUs from './components/aboutUs/aboutUs'
import MissionAndVission from './components/missionAndVission/missionAndVission'
import Footer from '../../components/footer/footer'


const listItemsRequirements = [
  {
    image: mayores18,
    text: '1. Ser mayor de 18 años'
  },

  {
    image: casa,
    text: '2. Ser residente en Colombia'
  },

  {
    image: identificacion,
    text: '3. Tener cédula de ciudadanía'
  },

  {
    image: celular,
    text: '4. Tener una linea de celular propia'
  },
]

const listItemsFunctioning = [
  {
    image: simulasCredito,
    text: '1. Simulas tu crédito'
  },

  {
    image: diligenciarSolicitud,
    text: '2. Diligencias la solicitud'
  },

  {
    image: firmar,
    text: '3. Validas tu información y firmas el contrato'
  },

  {
    image: dineroDirecto,
    text: '4. Recibes el dinero directo a tu cuenta'
  },
]

const Landing = () => {

  return (
    <div id='main'>
      <Banner />
      <SectionItemsInfo
        items={listItemsRequirements}
        title='Obtén tu crédito en línea con solo 4 sencillos pasos'
      />

      <Allies />

      <SectionItemsInfo
        items={listItemsFunctioning}
        title='¿Cómo hacerlo posible?'
      />

      <AboutUs />

      <MissionAndVission />

      <FAQ />

    </div>
  )
}

export default Landing