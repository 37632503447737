// Styles
import styles from './allies.module.css'
// images
import logo_myway from '../../../../images/logo_myway_Platform.png'
import Logo_payvalida from '../../../../images/Logo-payvalida-lila-png.png'
import Logo_Datacredito_Experian from '../../../../images/Logo-Datacredito-Experian.png'
import cropped_logo_autentic from '../../../../images/cropped-logo-autentic-para-web-09-1.png'
import allies from '../../../../images/iconos-pagina/aliados/allies.png'

const Allies = () => {

  return (
    <section className={styles.sectionAllies}>
      <div className={styles.container_aliados}>
        <h2>Nuestros aliados</h2>
      </div>
      <div className={styles.marcas}>
        <div className={styles.marca}>
          <img className={styles.logo_myway} src={logo_myway} alt="myway" />
        </div>
        <div className={styles.marca}>
          <img className={styles.logo_payvalida} src={Logo_payvalida} alt="payvalidad" />
        </div>
        <div className={styles.marca}>
          <img className={styles.logo_payvalida} src={allies} alt="Aliados" />
        </div>
        <div className={styles.marca}>
          <img className={styles.logo_datacredito} src={Logo_Datacredito_Experian} alt="datacredito" />
        </div>
        <div className={styles.marca}>
          <img className={styles.logo_autentic} src={cropped_logo_autentic} alt="autentic" />
        </div>
      </div>
    </section>
  )
}

export default Allies